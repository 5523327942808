import { IdCard } from '@styled-icons/boxicons-regular'
import { AssignmentInd } from '@styled-icons/material/AssignmentInd'
import { BarChart } from '@styled-icons/material/BarChart'
import { ListAlt } from '@styled-icons/material/ListAlt'
import { ReportProblem } from '@styled-icons/material/ReportProblem'
import { SpaceDashboard } from '@styled-icons/material/SpaceDashboard'
import { Storefront } from '@styled-icons/material/Storefront'
import { Workspaces } from '@styled-icons/material/Workspaces'
import NavItem from 'components-v2/molecules/SideNavItem'
import { LabelTypes } from 'constants/index'
import Privva from 'Privva'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { getPageUrl } from 'utils/url'

const SidebarWrapper = styled.div`
  width: 100px;
  height: 100%;
  position: fixed;
  top: -1px;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  background-color: #f5f5f5;
  display: flex;
  z-index: 1031;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #e0e0e0;
`
const SvgWrapper = styled.div`
  width: 101%;
  height: 56px;

  svg {
    width: 100%;
    height: 100%;
    --bg-color: ${Privva.Utils.theme.bannerColor};
    fill: var(--bg-color);
    stroke: #e7e7e7;
    stroke-width: 1px;
  }
`

const Sidebar = ({
  isLoggedIn,
  isClient,
  isVendor,
  isLibraryVendor,
  autoAssessEnabled,
  currentController,
  authorizations,
  currentOrgRole,
}) => (
  <SidebarWrapper>
    <SvgWrapper>
      <svg
        id="side-nav"
        viewBox="0 0 100 55"
        width="100"
        height="55"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M 0 0 L 100 0 L 100 55 C 100 55 72.397 50.412 60.525 29.776 C 48.655 9.139 0.032 16.018 0.032 16.018 L 0.032 -0.033 Z" />
      </svg>
    </SvgWrapper>
    {isLoggedIn && isClient && authorizations?.canAccessCurrentOrg && (
      <>
        {authorizations?.canListProjects && (
          <NavItem
            href={getPageUrl('clientProjects')}
            isActive={currentController === 'client/projects'}
            icon={Workspaces}
            label="Projects"
          />
        )}
        {authorizations?.canShowAssessmentTemplate && (
          <NavItem
            href={getPageUrl('clientAssessmentTemplates')}
            isActive={currentController === 'client/assessment_templates'}
            icon={SpaceDashboard}
            label="Templates"
          />
        )}
        <NavItem
          href={getPageUrl('clientVendors')}
          isActive={currentController === 'client/vendors'}
          icon={Storefront}
          label={LabelTypes.VENDORS}
        />
        {authorizations?.canListIssues && (
          <NavItem
            href={getPageUrl('clientIssues')}
            isActive={currentController === 'client/issues'}
            icon={ReportProblem}
            label="Issues"
          />
        )}
        {autoAssessEnabled && authorizations?.canManageAssistCurrentOrg && (
          <NavItem
            href={getPageUrl('assistAssessments')}
            isActive={currentController.startsWith('assist/')}
            icon={AssignmentInd}
            label="AutoAssess"
          />
        )}
        {currentOrgRole !== 'status_only' && (
          <NavItem
            href={getPageUrl('clientReports')}
            icon={BarChart}
            label="Reports"
            isActive={currentController === 'client/reports'}
          />
        )}
      </>
    )}
    {isVendor && (
      <>
        {isLibraryVendor && (
          <NavItem
            href={getPageUrl('libraryManageProfile')}
            isActive={currentController === 'library/manage/profiles'}
            icon={IdCard}
            label="Profile"
          />
        )}
        <NavItem
          href={getPageUrl('vendorAssessments')}
          icon={ListAlt}
          isActive={currentController === 'vendor/assessments'}
          label="Assessments"
        />
        <NavItem
          href={getPageUrl('vendorIssues')}
          icon={ReportProblem}
          isActive={currentController === 'vendor/issues'}
          label="Issues"
        />
      </>
    )}
  </SidebarWrapper>
)

Sidebar.propTypes = {
  isLoggedIn: PropTypes.bool,
  isClient: PropTypes.bool,
  autoAssessEnabled: PropTypes.bool,
  currentController: PropTypes.string.isRequired,
  authorizations: PropTypes.shape({
    canAccessCurrentOrg: PropTypes.bool,
    canLimitedAccessCurrentOrg: PropTypes.bool,
    canManageCurrentOrg: PropTypes.bool,
    canManageAssistCurrentOrg: PropTypes.bool,
    canShowCurrentOrg: PropTypes.bool,
    canListProjects: PropTypes.bool,
    canListIssues: PropTypes.bool,
    canShowAssessmentTemplate: PropTypes.bool,
    canAdmin: PropTypes.bool,
    canManageAssist: PropTypes.bool,
  }),
  currentOrgRole: PropTypes.string,
  isVendor: PropTypes.bool,
  isLibraryVendor: PropTypes.bool,
}

export default Sidebar
