import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import groupBy from 'lodash/groupBy'
import size from 'lodash/size'
import pick from 'lodash/pick'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import { ThemeProvider } from 'styled-components'
import Icon from 'components-v2/atoms/Icon'
import Tabs from 'components-v2/molecules/Tabs'
import { Row, Col } from 'components-v2/organisms/Layout'
import PageHeader from 'components-v2/organisms/PageHeader'
import Highlight from 'react-highlight-words'
import { getUserPreferences } from 'apis'
import Widget from './Widget'
import { CategoryTypes, Categories } from './consts'
import {
  Wrapper,
  theme,
  CategoryWrapper,
  CategoryHead,
  CategoryIconWrapper,
  CategoryName,
  CategorySize,
  StyledSearchInput,
} from './styles'

const ALL = 'all'

const PINNED = 'pinned'

const View = ({ reports, organizationId, ...rest }) => {
  const [categoryFilter, setCategoryFilter] = React.useState('all')
  const [searchTerm, setSearchTerm] = React.useState('')

  const [userReportPins, setUserReportPins] = React.useState([])

  const reportsByCategory = React.useMemo(
    () => groupBy(reports, 'category'),
    [reports],
  )

  const pinnedReportsByCategories = React.useMemo(() => {
    const pinnedReports = reports.filter((report) =>
      userReportPins.includes(report.key),
    )
    return groupBy(pinnedReports, 'category')
  }, [userReportPins, reports])

  const pinnedCategories = React.useMemo(
    () =>
      Object.keys(Categories).reduce((result, key) => {
        if (size(pinnedReportsByCategories[key]) > 0) {
          result[key] = Categories[key]
        }
        return result
      }, {}),
    [pinnedReportsByCategories],
  )

  const filteredCategories = React.useMemo(
    () =>
      categoryFilter === ALL
        ? Categories
        : categoryFilter === PINNED
        ? pinnedCategories
        : pick(Categories, [categoryFilter]),
    [categoryFilter, pinnedCategories],
  )

  const filteredReportsByCategory = React.useMemo(() => {
    const categoryReports =
      categoryFilter === PINNED ? pinnedReportsByCategories : reportsByCategory
    const matchesSearch = (report, category) => {
      if (!searchTerm.trim()) return true

      const lowercasedTerm = searchTerm.toLowerCase()
      const searchableFields = filter(
        [
          report.name,
          report.title,
          Categories[category]?.label,
          report.description,
        ],
        Boolean,
      )

      return searchableFields.some((field) =>
        includes(field.toLowerCase(), lowercasedTerm),
      )
    }

    if (!searchTerm.trim()) return categoryReports

    return Object.entries(categoryReports).reduce(
      (filtered, [category, filteredReports]) => {
        const matchedReports = filteredReports.filter((report) =>
          matchesSearch(report, category),
        )

        if (matchedReports.length > 0) {
          filtered[category] = matchedReports
        }

        return filtered
      },
      {},
    )
  }, [reportsByCategory, pinnedReportsByCategories, searchTerm, categoryFilter])

  React.useEffect(() => {
    const fetchUserPreferences = async () => {
      const response = await getUserPreferences()
      setUserReportPins(response.data.reports?.pins ?? [])
    }
    fetchUserPreferences()
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Wrapper {...rest}>
        <PageHeader title="Reporting center" />
        <Tabs activeKey={categoryFilter} onChange={setCategoryFilter}>
          <Tabs.Tab eventKey={ALL} title="All Reports" />
          <Tabs.Tab eventKey={PINNED} title="Pinned" />
          <Tabs.Tab eventKey={CategoryTypes.SUMMARY} title="Summaries" />
          <Tabs.Tab
            eventKey={CategoryTypes.ORGANIZATION_COMPARISONS}
            title="Organization Comparisons"
          />
          <Tabs.Tab
            eventKey={CategoryTypes.REMEDIATION_MANAGEMENT}
            title="Remediation Management"
          />
          <Tabs.Tab
            eventKey={CategoryTypes.PROGRESS_TRACKING}
            title="Progress Tracking"
          />
          <Tabs.Tab
            eventKey={CategoryTypes.DATA_EXPORTS}
            title="Data Exports"
          />
        </Tabs>
        <StyledSearchInput
          type="text"
          placeholder="Search reports..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e)}
        />
        {map(filteredCategories, (category, key) => {
          const reportsInCategory = filteredReportsByCategory[key] || []
          if (reportsInCategory.length === 0) {
            return null
          }

          return (
            <CategoryWrapper key={key}>
              <CategoryHead>
                <CategoryIconWrapper>
                  <Icon icon={category.icon} />
                </CategoryIconWrapper>
                <CategoryName>
                  <Highlight
                    searchWords={searchTerm.trim().split(/\s+/)}
                    autoEscape
                    textToHighlight={category.label}
                    highlightStyle={{ padding: 0 }}
                  />
                </CategoryName>
                <CategorySize>({size(reportsInCategory)})</CategorySize>
              </CategoryHead>
              <Row>
                {map(reportsInCategory, (report, index) => (
                  <Col md={3} key={index}>
                    <Widget
                      {...report}
                      reportKey={report.key}
                      color={category.color}
                      searchTerm={searchTerm}
                      description={report.description}
                      isPinned={userReportPins?.includes(report.key) ?? false}
                      currentPins={userReportPins ?? []}
                      organizationId={organizationId}
                      setUserReportPins={setUserReportPins}
                    />
                  </Col>
                ))}
              </Row>
            </CategoryWrapper>
          )
        })}
        {size(filteredReportsByCategory) === 0 && (
          <p>No reports found matching your criteria.</p>
        )}
      </Wrapper>
    </ThemeProvider>
  )
}

View.propTypes = {
  reports: PropTypes.array.isRequired,
  organizationId: PropTypes.string.isRequired,
}

export default View
