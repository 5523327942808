import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'components-v2/atoms/Icon'
import Highlight from 'react-highlight-words' // For highlighting search terms
import { Thumbtack } from '@styled-icons/fa-solid'
import { useUpdateUserPreferences } from 'apis'
import { compose, withAuthorize, withReactQuery } from 'hocs'
import { Wrapper, Title, IconWrapper, TitleWrapper } from './styles'

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase(),
  )
}

const Widget = ({
  title,
  description,
  href,
  searchTerm,
  isPinned,
  reportKey,
  organizationId,
  currentPins,
  setUserReportPins,
  ...rest
}) => {
  const searchWords = searchTerm.trim().split(/\s+/)

  const { mutateAsync: updateUserPreferences } = useUpdateUserPreferences()

  const handlePinClick = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const selectedPins = isPinned
      ? currentPins.filter((pin) => pin !== reportKey)
      : [...currentPins, reportKey]

    updateUserPreferences({
      data: {
        preferences: {
          organization_id: organizationId,
          reports: {
            pins: [...selectedPins],
          },
        },
      },
    })
      .then((data) => {
        setUserReportPins(data.reports.pins)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <Wrapper
      {...rest}
      href={href}
      as={href ? 'a' : undefined}
      role="button"
      tabIndex={href ? undefined : 0}
    >
      <TitleWrapper>
        <Title>
          <Highlight
            searchWords={searchWords}
            autoEscape
            textToHighlight={toTitleCase(title)}
            highlightStyle={{ padding: 0 }}
          />
        </Title>
        <IconWrapper pinned={isPinned}>
          <Icon onClick={handlePinClick} icon={Thumbtack} />
        </IconWrapper>
      </TitleWrapper>
      <p>
        <Highlight
          searchWords={searchWords}
          autoEscape
          textToHighlight={description}
          highlightStyle={{ padding: 0 }}
        />
      </p>
    </Wrapper>
  )
}

Widget.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  href: PropTypes.string,
  color: PropTypes.string,
  searchTerm: PropTypes.string,
  isPinned: PropTypes.bool,
  reportKey: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  currentPins: PropTypes.array.isRequired,
  setUserReportPins: PropTypes.func.isRequired,
}

Widget.defaultProps = {
  searchTerm: '',
}

export default React.memo(compose(withAuthorize(), withReactQuery())(Widget))
