import { colors } from 'styles'

const IssuePriorityTypes = {
  INFORMATIONAL: 'informational',
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high',
  CRITICAL: 'critical',
}

export const IssuePriorityOptions = [
  {
    value: IssuePriorityTypes.INFORMATIONAL,
    label: 'Informational',
    color: colors.MED_DARK_GREY,
  },
  { value: IssuePriorityTypes.LOW, label: 'Low', color: colors.GREEN },
  { value: IssuePriorityTypes.MEDIUM, label: 'Medium', color: colors.BLUE },
  { value: IssuePriorityTypes.HIGH, label: 'High', color: colors.ORANGE },
  { value: IssuePriorityTypes.CRITICAL, label: 'Critical', color: colors.RED },
]

export default IssuePriorityTypes
