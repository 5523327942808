import React from 'react'
import PropTypes from 'prop-types'
import { getPageUrl } from 'utils/url'
import { LabelTypes } from 'constants/index'
import { useAuthorizations, useModal } from 'hooks'
import map from 'lodash/map'
import kebabCase from 'lodash/kebabCase'
import filter from 'lodash/filter'
import { compose, withAuthorize, withReactQuery } from 'hocs'
import View from './View'
import { CategoryTypes } from './consts'
import ResponseResultsModal from './ResponseResultsModal'
import ResultsComparisonModal from './ResultsComparisonModal'
import VendorAssessmentModal from './VendorAssessmentModal'
import ExportVendorDetailsModal from './ExportVendorDetailsModal'
import ExportVendorAssessmentsModal from './ExportVendorAssessmentsModal'
import ExportIssuesModal from './ExportIssuesModal'
import ExportVendorResponsesModal from './ExportVendorResponsesModal'
import ExportResponseComparisonModal from './ExportResponseComparisonModal'
import VendorAnswerComparisonModal from './VendorAnswerComparisonModal'
import RiskProfileModal from './RiskProfileModal'

const ReportsViewController = ({
  showGloborg,
  enableRiskFacets,
  enableOfacMatching,
  organizationId,
}) => {
  const [openVendorAssessmentModal] = useModal(VendorAssessmentModal)
  const [openResultsComparisonModal] = useModal(ResultsComparisonModal)
  const [openVendorAnswerComparisonModal] = useModal(
    VendorAnswerComparisonModal,
  )
  const [openResponseResultsModal] = useModal(ResponseResultsModal)
  const [openRiskProfileModal] = useModal(RiskProfileModal)
  const [openExportResponseComparisonModal] = useModal(
    ExportResponseComparisonModal,
  )
  const [openExportVendorDetailsModal] = useModal(ExportVendorDetailsModal)
  const [openExportVendorAssessmentsModal] = useModal(
    ExportVendorAssessmentsModal,
  )
  const [openExportIssuesModal] = useModal(ExportIssuesModal)
  const [openExportVendorResponsesModal] = useModal(ExportVendorResponsesModal)
  const [
    canViewAssessments,
    canReportAssessments,
    canExportAssessments,
    canViewVendors,
    canExportVendors,
    canViewIssues,
    canExportIssues,
  ] = useAuthorizations(
    ['show', 'Assessment'],
    ['report', 'Assessment'],
    ['export', 'Assessment'],
    ['show', 'Organization'],
    ['export', 'Organization'],
    ['show', 'Issue'],
    ['export', 'Issue'],
  )
  const reports = React.useMemo(() => {
    const reportsArray = [
      {
        title: `${LabelTypes.VENDOR} assessment report`,
        description: `View overall and section level ratings for a given ${LabelTypes.VENDOR.toLowerCase()} and assessment.`,
        onClick: () => openVendorAssessmentModal(),
        category: CategoryTypes.SUMMARY,
        fileIcon: 'fa fa-file-text-o',
        enabled: canReportAssessments,
      },
      {
        title: 'Results comparison report',
        description: `Compare top line rating for like assessments across ${LabelTypes.VENDORS.toLowerCase()}.`,
        onClick: () => openResultsComparisonModal(),
        category: CategoryTypes.ORGANIZATION_COMPARISONS,
        fileIcon: 'fa fa-file-text-o',
        enabled: canViewAssessments,
      },
      {
        title: `${LabelTypes.VENDOR} Answer Comparison report`,
        description: `Compare question level scores for like assessments across ${LabelTypes.VENDORS.toLowerCase()} in a grid format.`,
        onClick: () => openVendorAnswerComparisonModal(),
        category: CategoryTypes.ORGANIZATION_COMPARISONS,
        fileIcon: 'fa fa-file-text-o',
        enabled: canViewAssessments,
      },
      {
        title: 'Response results report',
        description: `Compare question level scores for like assessments across ${LabelTypes.VENDORS.toLowerCase()} in a presentation format.`,
        onClick: () => openResponseResultsModal(),
        category: CategoryTypes.ORGANIZATION_COMPARISONS,
        fileIcon: 'fa fa-file-text-o',
        enabled: canViewAssessments,
      },
      {
        title: `${LabelTypes.VENDOR} Assessments Status`,
        description: `Filter ${LabelTypes.VENDORS.toLowerCase()} and review summarized assessment status.`,
        href: getPageUrl('clientVendorAssessmentsStatusReport'),
        category: CategoryTypes.ORGANIZATION_COMPARISONS,
        fileIcon: 'fa fa-file-text-o',
        enabled: canViewVendors,
      },
      {
        title: `${LabelTypes.VENDOR} Risk Measures`,
        description: `Filter ${LabelTypes.VENDORS.toLowerCase()} and review risk facet set information.`,
        href: getPageUrl('clientVendorFacetSetsReport'),
        category: CategoryTypes.ORGANIZATION_COMPARISONS,
        fileIcon: 'fa fa-file-text-o',
        enabled: enableRiskFacets && canViewVendors,
      },
      {
        title: 'OFAC Sanctions List',
        description: 'Filter and review OFAC sanctioned entities and matches.',
        href: getPageUrl('clientVendorFacetSetsReport'),
        category: CategoryTypes.ORGANIZATION_COMPARISONS,
        fileIcon: 'fa fa-file-text-o',
        enabled: false && enableOfacMatching && canViewVendors,
      },
      {
        title: 'OFAC Status Report',
        description: `View summary of ${LabelTypes.VENDOR.toLowerCase()} OFAC statuses.`,
        href: getPageUrl('clientVendorOfacStatusReport'),
        category: CategoryTypes.ORGANIZATION_COMPARISONS,
        fileIcon: 'fa fa-file-text-o',
        enabled: enableOfacMatching && canViewVendors,
      },
      {
        title: 'Assessment Status Report',
        description: 'View summary of assessments by status.',
        href: getPageUrl('clientAssessmentsStatusReport'),
        category: CategoryTypes.SUMMARY,
        fileIcon: 'fa fa-file-text-o',
        enabled: canReportAssessments,
      },
      {
        title: 'Assessment Metrics by Month',
        description:
          'View assessment counts and average ratings over a date range.',
        href: getPageUrl('clientAssessmentsMonthlyMetrics'),
        category: CategoryTypes.SUMMARY,
        fileIcon: 'fa fa-file-text-o',
        enabled: canReportAssessments,
      },
      {
        title: 'Risk Profile Report',
        description: `View Summary of Risk Profile for ${
          LabelTypes.VENDOR.beginsWithVowel() ? 'an' : 'a'
        } ${LabelTypes.VENDOR.toLowerCase()}, including continuous monitoring data`,
        onClick: () => openRiskProfileModal(),
        category: CategoryTypes.SUMMARY,
        fileIcon: 'fa fa-file-text-o',
        enabled: showGloborg && canViewVendors,
      },
      {
        title: 'Issue Summary Report',
        description: `View status, priority, and resolution across all ${LabelTypes.VENDORS.toLowerCase()}.`,
        href: getPageUrl('clientIssuesSummary'),
        category: CategoryTypes.REMEDIATION_MANAGEMENT,
        fileIcon: 'fa fa-file-text-o',
        enabled: canViewIssues,
      },
      {
        title: 'Issue Category Report',
        description: 'View status, priority, and resolution by category.',
        href: getPageUrl('clientIssuesSummary', undefined, {
          dimension: 'issue_category',
        }),
        category: CategoryTypes.REMEDIATION_MANAGEMENT,
        fileIcon: 'fa fa-file-text-o',
        enabled: canViewIssues,
      },
      {
        title: `${LabelTypes.VENDOR} Issues Report`,
        description: `View status, priority, and resolution by ${LabelTypes.VENDOR.toLowerCase()}.`,
        href: getPageUrl('clientIssuesSummary', undefined, {
          dimension: 'vendor',
        }),
        category: CategoryTypes.REMEDIATION_MANAGEMENT,
        fileIcon: 'fa fa-file-text-o',
        enabled: canViewIssues,
      },
      {
        title: 'Export Detailed Response Comparison',
        description: `Compare detailed question-level data across ${LabelTypes.VENDORS.toLowerCase()} who completed the same assessment template.`,
        onClick: () => openExportResponseComparisonModal(),
        category: CategoryTypes.DATA_EXPORTS,
        fileIcon: 'fa fa-file-excel-o',
        enabled: canExportAssessments,
      },
      {
        title: `Export ${LabelTypes.VENDOR.toLowerCase()} details`,
        description: `Export ${LabelTypes.VENDOR.toLowerCase()} contact, access, and other details in CSV or XLSX format.`,
        onClick: () => openExportVendorDetailsModal(),
        category: CategoryTypes.DATA_EXPORTS,
        fileIcon: 'fa fa-file-excel-o',
        enabled: canExportVendors,
      },
      {
        title: `Export ${LabelTypes.VENDOR} Assessments`,
        description: `Export assessment status, rating, grade, and other details across ${LabelTypes.VENDORS.toLowerCase()} as CSV.`,
        onClick: () => openExportVendorAssessmentsModal(),
        category: CategoryTypes.DATA_EXPORTS,
        fileIcon: 'fa fa-file-excel-o',
        enabled: canExportAssessments,
      },
      {
        title: 'Export All Issues',
        description: `Export issue priority, status, and other details across ${LabelTypes.VENDORS.toLowerCase()}.`,
        onClick: () => openExportIssuesModal(),
        category: CategoryTypes.DATA_EXPORTS,
        fileIcon: 'fa fa-file-excel-o',
        enabled: canExportIssues,
      },
      {
        title: `Export ${LabelTypes.VENDOR} Responses`,
        description: `View all responses for a specific assessment by ${
          LabelTypes.VENDOR.beginsWithVowel() ? 'an' : 'a'
        } ${LabelTypes.VENDOR.toLowerCase()}.`,
        onClick: () => openExportVendorResponsesModal(),
        category: CategoryTypes.DATA_EXPORTS,
        fileIcon: 'fa fa-file-excel-o',
        enabled: canExportAssessments,
      },
      {
        title: 'Cycle Time Report',
        description:
          'View the time an assessment spends in each status (Time to Start, Time to Submit, Time to Review) before completion.',
        href: getPageUrl('clientAssessmentCycleTimeReport'),
        category: CategoryTypes.PROGRESS_TRACKING,
        fileIcon: 'fa fa-file-text-o',
        enabled: canReportAssessments,
      },
    ]

    return filter(
      map(reportsArray, (report) => ({
        ...report,
        key: kebabCase(report.title),
      })),
      'enabled',
    )
  }, [
    openVendorAssessmentModal,
    openResultsComparisonModal,
    openVendorAnswerComparisonModal,
    openResponseResultsModal,
    openRiskProfileModal,
    openExportResponseComparisonModal,
    openExportVendorDetailsModal,
    openExportVendorAssessmentsModal,
    openExportIssuesModal,
    openExportVendorResponsesModal,
    canViewAssessments,
    canReportAssessments,
    canExportAssessments,
    canViewVendors,
    canExportVendors,
    canViewIssues,
    canExportIssues,
    showGloborg,
    enableRiskFacets,
    enableOfacMatching,
  ])
  return <View reports={reports} organizationId={organizationId} />
}

ReportsViewController.propTypes = {
  showGloborg: PropTypes.bool,
  enableRiskFacets: PropTypes.bool,
  enableOfacMatching: PropTypes.bool,
  organizationId: PropTypes.string,
}

export default compose(withAuthorize(), withReactQuery())(ReportsViewController)
